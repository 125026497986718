import {Router, Route, hashHistory} from 'react-router';
import historyListener from '../analyticsHistoryListener';

/* Import update components */
import NxUpdateContainer from '../modules/updates/NxUpdateContainer';

const UpdatesComponent = () => {
  const indexPath = '/updates';
  hashHistory.listen(historyListener(indexPath));

  return (
    <Router history={hashHistory}>
      <Route path={indexPath} component={NxUpdateContainer}/>
    </Router>
  );
};

export default UpdatesComponent;
