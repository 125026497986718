import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VMChromeMenus, { PageAccess, IpimsLicenseType, ProductEdition, navigationIds } from 'insightvm-ui-nav-menus';
import PolicyComponent from './policies/PolicyComponent';
import UpdatesComponent from './updates/UpdatesComponent';
import IpimsSessionConflict from './modules/errors/IpimsSessionConflict';
import getNavWidth from './utils/getNavWidth';
import buildUserNavigationPrivileges from './utils/buildUserNavigationPrivileges';
import { NotificationCenterContainer, NotificationCenterItemContainer, Portal, ThemeProvider } from '@rapid7/rapid7-ui';
import NotificationsService from './modules/notifications/NotificationsService';
import { getLocalData, setLocalData } from './utils/localStorageUtils';
import NotificationBanner, { getGracePeriodBannerColor } from './components/NotificationBanner';

const MAINTENANCE = 'BOOTMODE_MAINTENANCE';

const AuthSource = {
	CONSOLE: 'CONSOLE',
	IPIMS: 'iPIMS',
	OKTA: 'OKTA'
};

const NotificationItemState = {
	CRITICAL: 'CRITICAL',
	MODERATE: 'WARNING',
	NORMAL: 'NONE'
};

const url = new URL(window.location);

const getTheme = () => {
	if (document.nxp.user && document.nxp.user.theme) {
		return document.nxp.user.theme.toLowerCase();
	}

	return 'dark';
};

const getLicenseType = ({ exposureAnalyticsSupported = false } = {}) => {
	if (exposureAnalyticsSupported) {
		return IpimsLicenseType.EXPOSURE_ANALYTICS;
	}

	return IpimsLicenseType.NONE;
};

const getIsFedrampHostedConsole = () => {
  return !!document.nxp.isFedrampHostedConsole;
};

const getComponent = (props) => {
	if (url.pathname.indexOf('ea/session-conflict-error.jsp') > -1) {
		return <IpimsSessionConflict {...props} />;
	}

	if (url.pathname.indexOf('policy/policies.jsp') > -1) {
		return <PolicyComponent />;
	}

	if (url.pathname.indexOf('admin/updates.jsp') > -1) {
		return <UpdatesComponent />;
	}

	return null;
};

const showSignupFlow = () => {
	$('.slide-in-dialog').css('display', 'none');
	$('#actions-top-menu-item').removeClass('notifications-selected');

	const event = document.createEvent('Event');

	event.initEvent('startConsoleActivation', true, false);

	document.dispatchEvent(event);
};

const getUsersPageAccess = (sessionId, bootMode) => {
	if (bootMode === MAINTENANCE) {
		return PageAccess.SINGLE;
	}

	if (sessionId) {
		return PageAccess.ALL;
	}

	return PageAccess.SINGLE_NO_USER;
};

const getDisplayName = (userName, isSSOEnabled, firstName, lastName) => {
	const displayName = [];
	const formattedUsername = userName || '';

	if (isSSOEnabled) {
		if (firstName) { displayName.push(firstName.trim()); }
		if (lastName) { displayName.push(lastName.trim()); }
	}
	return displayName.length ? displayName.join(' ') : formattedUsername;
};

const createOnDismissNotification = ({ notificationsState, setNotificationsState, sessionID, notification }) => () => {
	const { disableNotification } = new NotificationsService();

	return disableNotification(sessionID, notification.id)
		.done(() => {
			const nonDeletedNotifications = notificationsState.filter((item) => {
				return item.id !== notification.id;
			});
			setNotificationsState(nonDeletedNotifications);
		})
		.fail(() => {
			const updatedNotifications = notificationsState.map((item) => {
				if (item.id === notification.id) {
					item.error = true;
				}

				return item;
			});

			setNotificationsState(updatedNotifications);
		});
};

$('html').addClass(getTheme());

const App = ({
	appOptions: { admin = false, bootMode, canUserSeeAdminTab = false, dashboardTourViewed = false, featureSet = {}, hasLatestNews, notifications = [], oemConfig = {}, releaseNotesURL, userName, userPrivileges = [], disabledFeatures = [] } = {},
	sessionID,
	skin: { helpURL, supportHelpURL, productName } = {},
	user: { firstName = null, isSSOEnabled = false, lastName = null, ssoGracePeriodDaysLeft = 0 } = {}
} = {}
) => {
	// Hack too force the left nav to be closed on page load, until the UI can handle it
	window.localStorage.setItem('chrome-nav-left-menu-open', JSON.stringify(false));
	const isCloudEnabled = featureSet.exposureAnalyticsSupported && oemConfig.oem && (admin || dashboardTourViewed);
	const [isExpanded, setIsExpanded] = useState(false);
	const [notificationsState, setNotificationsState] = useState(notifications || []);
	const maxNavWidthRef = useRef(0);
	const firstRef = useRef(true);
	const ChildComponent = getComponent();
	const originalMainMarginLeftRef = useRef(0);
	const originalSlidingElementsLeftRef = useRef({});
	const license = getLicenseType(featureSet);
	const pageMargin = 216;
	const slideElementsBy = 151;
	const pageAccess = getUsersPageAccess(sessionID, bootMode);
	const [isNotificationCenterOpen, setIsNotificationCenterOpen] = useState(!hasLatestNews);
	const { t } = useTranslation();
	const isMaintenanceMode = bootMode === MAINTENANCE;
	const productEdition = featureSet.edition === ProductEdition.UNLICENSED ? featureSet.defaultEdition : featureSet.edition;
	const navigationPrivileges = buildUserNavigationPrivileges({ admin, canUserSeeAdminTab, isCloudEnabled, featureSet, oemConfig, productEdition, userPrivileges, disabledFeatures });
	const [isGracePeriodBannerVisible, setIsGracePeriodBannerVisible] = useState(ssoGracePeriodDaysLeft > 0 && !getLocalData(`${sessionID}-snoozeGraceBanner`));
	const expandNav = () => {
		const mainElements = $('.Main');
		const elementsToSlide = $('.slide-with-nav');

		if (!mainElements.length) {
			return;
		}

		if (firstRef.current) {
			maxNavWidthRef.current = getNavWidth();
			originalMainMarginLeftRef.current = mainElements.first().css('marginLeft');
			const slidingElementsLeftMargin = {};

			for (let i = 0; i < elementsToSlide.length; i++) {
				slidingElementsLeftMargin[i] = $(elementsToSlide[i]).css('marginLeft');
			}

			originalSlidingElementsLeftRef.current = slidingElementsLeftMargin;
			firstRef.current = false;
		}

		for (let i = 0; i < mainElements.length; i++) {
			$(mainElements[i]).css('marginLeft', isExpanded ? originalMainMarginLeftRef.current : maxNavWidthRef.current + pageMargin);
		}

		for (let i = 0; i < elementsToSlide.length; i++) {
			$(elementsToSlide[i]).css('marginLeft', isExpanded ? originalSlidingElementsLeftRef.current[i] : maxNavWidthRef.current + slideElementsBy);
		}

		setIsExpanded(!isExpanded);
	};

	useEffect(() => {
		$('#nx-react-app .chrome-sidebar__control').off().on('click', expandNav);
	}, [isExpanded]);

	useEffect(() => {
		if (pageAccess === PageAccess.SINGLE_NO_USER || pageAccess === PageAccess.SINGLE) {
			$('body').addClass('navigation-bar-hidden');
			$('.chrome-sidebar').addClass('navigation-bar-hidden');
		}

		Array.from(document.querySelectorAll('li[id$=left-nav__item--inactive]')).forEach(function (element) {
			element.addEventListener('click', (event) => {
				event.stopPropagation();

				if (isCloudEnabled && admin && !dashboardTourViewed) {
					showSignupFlow();
				}
			});
		});
	}, [pageAccess]);

	useEffect(() => {
		$(document).ready(function () {
			$('.Main').on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function () {
				window.dispatchEvent(new Event('resize'));
			});
		});
	}, []);

	return (
		<ThemeProvider theme={getTheme()}>
			<>
				{isGracePeriodBannerVisible && sessionID &&
					<NotificationBanner {...{
						color: getGracePeriodBannerColor(ssoGracePeriodDaysLeft),
						notificationText: t(`${ssoGracePeriodDaysLeft === 1 ? 'banner:gracePeriodLastDay' : 'banner:gracePeriod'}`, { ssoGracePeriodDaysLeft: ssoGracePeriodDaysLeft }),
						isDismissable: ssoGracePeriodDaysLeft > 3,
						onDismiss: () => {
							setIsGracePeriodBannerVisible(false);
							setLocalData(`${sessionID}-snoozeGraceBanner`, true);
						}
					}} />
				}
				<VMChromeMenus
          isFedRamp={getIsFedrampHostedConsole()}
					isSidebarDefaultOpen={false}
					baseUrl={url}
					pageAccess={pageAccess}
					className={isMaintenanceMode ? 'maintenance-mode' : ''}
					cloudUrl={isCloudEnabled ? `${window.location.origin}/ea/redirect.jsp?page=${encodeURIComponent('#')}` : ''}
					consoleUrl={window.location.origin}
					helpUrl={helpURL}
					ipimsLicenseType={license}
					isConsoleLinksInactive={isMaintenanceMode}
					isCloudLinksInactive={isCloudEnabled && admin && !dashboardTourViewed}
					authSource={AuthSource.CONSOLE}
					isFromConsole={true}
					isFromPlatform={false}
					isProduct={false}
					currentProductCode={productName === 'InsightVM' ? 'IVM' : 'NEX'}
					currentProductToken={'IVM'}
					notificationCount={notificationsState.length}
					onLeftItemSelected={() => {
						if (!isExpanded) {
							expandNav();
						}
					}}
					onTopItemSelected={({ id }) => {
						if (id === navigationIds.NOTIFICATION) {
							setIsNotificationCenterOpen(true);
						} else if (id === navigationIds.EXPLORER && isCloudEnabled && admin && !dashboardTourViewed) {
							showSignupFlow();
						} else if (id === navigationIds.EXPLORER && isCloudEnabled) {
							window.location.href = `${window.location.origin}/ea/redirect.jsp?page=${encodeURIComponent('#/dashboard?qb=1')}`;
						}
					}}
					onLogout={() => window.location.href = '/logout.html'}
					pathname={window.location.pathname}
					productEdition={productEdition}
					productLogoUrl='/style/image/product_logo.png'
					releaseNotesUrl={releaseNotesURL}
					supportUrl={supportHelpURL}
					user={{
						isAdmin: admin,
						name: getDisplayName(userName, isSSOEnabled, firstName, lastName),
						privileges: navigationPrivileges
					}}
				>
					<div className={'app-content'}>
						{sessionID && (
							<>
								<Portal selector="body">
									<NotificationCenterContainer
										notificationCount={notificationsState.length}
										isOpen={isNotificationCenterOpen}
										onClose={() => setIsNotificationCenterOpen(false)}
									>
										{!!notificationsState.length && notificationsState.map((currentNotification) => {
											return (
												<NotificationCenterItemContainer
													key={currentNotification.id}
													includeIcon={false}
													notification={currentNotification}
													{...(!!currentNotification.moreInformationLink && {
														onClick: () => {
															if (currentNotification.target) {
																window.open(currentNotification.moreInformationLink, currentNotification.target);
															} else {
																window.location.href = currentNotification.moreInformationLink;
															}
														}
													})}
													//only show dismiss if a translation exists, NEX-52146
													{...(!!t(`notification:${currentNotification.id}.dismiss`) && { onDismiss: createOnDismissNotification({ notificationsState, setNotificationsState, notification: currentNotification, sessionID }) })}
													state={NotificationItemState[currentNotification.criticality]}
												>
													<>
														{t(`notification:${currentNotification.id}.text`).split('\n').map((str) => <p>{str}</p>)}
														{!!currentNotification.error && <span class="notification-center-item-error">{t('notification:error')}</span>}
													</>
												</NotificationCenterItemContainer>
											);
										})}
									</NotificationCenterContainer>
								</Portal>
								{ChildComponent && (
									<div className="Main nopadding" >
										<div className="MainContent">
											<div className="PortletArea">
												{ChildComponent}
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</VMChromeMenus>
			</>
		</ThemeProvider>
	);
};

export default App;
