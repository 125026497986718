import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import NxUpdateCard from './components/NxUpdateCard';
import NxUpdateHeader from './NxUpdateHeader';
import $ from 'jquery';
import './scss/nexpose-update-card.scss';

class NxUpdateContainer extends React.Component {

   constructor(props) {
      super(props);
      _.bindAll(this, 'handleUpdateClick', 'closeModal', 'confirmUpdate', 'checkForUpdates', 'closeUpdateResponseModal');
   }

   state = {
      recentReleases: [],
      olderReleases: [],
      currentReleases: [],
      updateInProgress: false,
      updateMessage: ''
   }

   confirmUpdate() {
      this.setState({ updateInProgress: true });
      $.ajax({
        type: 'POST',
        url: '/data/admin/updates/product',
        data: {
          'updateID' :  this.state.confirmedUpdateID
        },
        headers: {
          nexposeCCSessionID: document.nxp.sessionID
        },
        dataType: 'json',
        success: (response) => {
          this.setState({ updateInProgress: false, updateComplete: true, showModal: false, updateMessage: response });
        },
        error: (response) => {
          this.setState({ updateInProgress: false, updateComplete: true, showModal: false, updateMessage: response.responseText });
        }
      });
   }

   closeModal() {
      this.setState({ showModal: false });
   }

   closeUpdateResponseModal() {
      this.setState({ updateComplete: false });
   }

   componentDidMount() {
      this.loadUpdateData(0);
   }

   handleUpdateClick(updateID, updateVersion) {
      this.setState({ showModal: true, confirmedUpdateVersion: updateVersion, confirmedUpdateID: updateID });
   }

   onReleaseNotesClick() {
      window.open('https://docs.rapid7.com/release-notes/nexpose/',
        '_blank',
        'noopener noreferrer'
      );
   }

   checkForUpdates() {
     this.setState({
       isUpdateCheckInProgress: true
     });
     $.ajax({
        url: '/data/admin/updates',
        type: 'POST',
        data: {
          'action' :  'check'
        },
        headers: {
          nexposeCCSessionID: document.nxp.sessionID
        },
        success: (response) => {
          this.loadUpdateData(response);
        },
        error: () => {
          this.loadUpdateData(0);
        }
      });
   }

   loadUpdateData(newCount) {
      $.get('/data/admin/updates/product', (response) => {
        if (response.length > 0) {
          const [installedVersion = {}] = response.filter((update) => update.isInstalled);
          const recentRelease = response[0];

           //Don't show the update message on page load.
           if (this.state.currentReleases.length > 0) {
              this.setState({
                updateCheckComplete: true,
                newUpdatesCount: parseInt(newCount)
              });
           } else if (this.state.releaseCount) {
              this.setState({
                updateCheckComplete: true,
                newUpdatesCount: 0
              });
           }

           this.setState({
             recentReleases   : _.slice(response, 1, 9),
             olderReleases    : _.slice(response, 9, response.length),
             currentReleases  : response,
             mostRecentRelease: recentRelease,
             isUserUpdated    : recentRelease.isInstalled,
             currentVersion   : installedVersion.version,
             releaseCount     : response.length,
             isUpdateCheckInProgress: false
           });
         }
      });
   }

  render() {
    const {t} = this.props;
    var recentReleaseSection = <div/>;
    if (this.state.recentReleases.length > 0) {
         recentReleaseSection = (<div className='nexpose-update-main-content'>
          <div className='header'>
            {t('recent_releases')}
          </div>
          <div className='nexpose-update-card-container'>
            { this.state.recentReleases.map(function(update){
                return (<NxUpdateCard
                  key                 = {update.updateID}
                  version             = {update.version}
                  date                = {update.date}
                  isInstalled         = {update.isInstalled}
                  updateID            = {update.updateID}
                  description         = {t('install_text', {versionNum: update.version})}
                  currentVersion      = {this.state.currentVersion}
                  onUpdateClick       = {this.handleUpdateClick}
                  onReleaseNotesClick = {this.onReleaseNotesClick}/>);
              }, this)
            }
         </div>
       </div>);
    }

    var updateButton;
    if (this.state.updateInProgress) {
      updateButton = (<Button bsStyle="primary" onClick={this.confirmUpdate} disabled><i className="fa fa-spinner fa-spin"></i> {t('updating')}</Button>);
    } else {
      updateButton = (<Button bsStyle="primary" onClick={this.confirmUpdate}>{t('update')}</Button>);
    }

    var updateText;
    if (this.state.updateCheckComplete) {
      if (this.state.newUpdatesCount === 0) {
        updateText = t('no_new_updates');
      } else if (this.state.newUpdatesCount === 1) {
        updateText = t('new_update_found', {updates: this.state.newUpdatesCount});
      } else {
        updateText = t('new_updates_found', {updates: this.state.newUpdatesCount});
      }
    }

    return (
      <div className="nexposeUpdatePage">
        <NxUpdateHeader
          currentVersion      = {this.state.currentVersion}
          installedVersion    = {this.state.currentVersion}
          isUserUpdated       = {this.state.isUserUpdated}
          newestVersion       = {this.state.mostRecentRelease}
          onUpdateClick       = {this.handleUpdateClick}
          onReleaseNotesClick = {this.onReleaseNotesClick}
          checkForUpdates     = {this.checkForUpdates}
          isUpdateCheckInProgress = {this.state.isUpdateCheckInProgress}
          updateText = {updateText}
        />
        {recentReleaseSection}

        {/* Confirmation Dialog  */}
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('confirm_update_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('confirm_update_body', {versionNum: this.state.confirmedUpdateVersion})}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="link" onClick={this.closeModal}>{t('close')}</Button>
            {updateButton}
          </Modal.Footer>
        </Modal>

      {/* Update response Dialog  */}
        <Modal show={this.state.updateComplete} onHide={this.closeUpdateResponseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('update_complete')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.updateMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="link" onClick={this.closeUpdateResponseModal}>{t('close')}</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

export default withTranslation(['update', 'translation'])(NxUpdateContainer);
