import $ from 'jquery';
import _ from 'lodash';
import axios from 'axios';
import {useRef} from 'react';
import {Router, Route, hashHistory, IndexRedirect} from 'react-router';
import historyListener from '../analyticsHistoryListener';

/* Import policy components */
import NxPolicy from 'rapid7-nexpose-policy-js/dist/nexpose-policy.min.js';
import 'rapid7-nexpose-policy-js/dist/nexpose-policy.min.css';
import {getPolicyFeatures} from './PolicyFeatures';

const initializeNxPolicy = () => {
  /* Configure policy component URLs to nexpose backend endpoints */
  return NxPolicy.init({
    features: getPolicyFeatures(),
    endPoints: {
      get({url, data, dataType = 'json'}) {
        return $.get({url, data, dataType});
      },
      post({url, data}) {
        return $.post({
          headers: {'nexposeCCSessionID': document.nxp.sessionID},
          url,
          data
        });
      },
      getOverallPolicyComplianceSummary() {
        return this.get({url: '/api/3/policy/summary'});
      },
      getPolicySummary(id) {
        return this.get({url: `/api/3/policies/${id}`})
          .then((data) => _.defaults(data, {
            passedRulesCount: 0,
            failedRulesCount: 0,
            notApplicableRulesCount: 0,
            passedAssetsCount: 0,
            failedAssetsCount: 0,
            notApplicableAssetsCount: 0,
            ruleCompliance: 0,
            ruleComplianceDelta: 0
          }));
      },
      getPolicyEditorLink(id) {
        return '/policy/policy-editor.jsp?policy_id=' + id + '&displayMode=edit';
      },
      getCloudPolicyEditorLink(policyId, nexPolicyId, mode, viewMode) {
        const cloudPolicyEditorUrl = encodeURIComponent(
          `#/policy/custom/${mode}/${policyId}?nexPolicyId=${nexPolicyId}&viewMode=${viewMode}`
        );

        return `/ea/redirect.jsp?page=${cloudPolicyEditorUrl}`;
      },
      getCloudPolicyEditorId(id) {
        return fetch(`/data/policies/${id}/policyUUID`);
      },
      syncPolicyToConsole(syncId) {
        return this.get({ url: `/data/policies/${syncId}/policySync` });
      },
      getCloudPolicyStatus() {
        return this.get({ url: '/data/optOutCPB' });
      },
      getPolicies(data) {
        return this.get({url: '/api/3/policies', data})
          .then((data) => {
            _.each(data.resources, (item) => _.defaults(item, {
              passedRulesCount: 0,
              failedRulesCount: 0,
              notApplicableRulesCount: 0,
              passedAssetsCount: 0,
              failedAssetsCount: 0,
              notApplicableAssetsCount: 0,
              ruleCompliance: 0,
              ruleComplianceDelta: 0
            }));
            return data;
          });
      },
      uploadFile({file, postData}) {
        let formData = new FormData();
        formData.append('nexposeCCSessionID', document.nxp.sessionID);
        _.each(postData, (key, value) => formData.append(value, key));
        formData.append('policy', file);

        return axios.post('/data/policy/policies/file', formData, {
          headers: {
            'Accept': 'text/plain,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8'
          }
        });
      },
      getSCAP12Info(file) {
        let formData = new FormData();
        formData.append('nexposeCCSessionID', document.nxp.sessionID);
        formData.append('policy', file);

        return axios.post('/data/policy/policies/scap12file', formData, {
          headers: {
            'Accept': 'text/plain,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8'
          }
        });
      },
      copyPolicy: (policyID) => {
        let formData = new FormData();
        formData.append('nexposeCCSessionID', document.nxp.sessionID);

        return axios.post(`/data/policy/${policyID}/copy`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
      },
      deletePolicies: (policyIDs) => {
        let formData = new FormData();
        formData.append('nexposeCCSessionID', document.nxp.sessionID);
        formData.append('policyIDs', policyIDs);

        return axios.post('/data/policy/delete', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
      },

      /**
       * Asset view -- assets
       */
      getOverallAssetComplianceSummary() {
        return this.get({url: '/data/policies/assets/summary'});
      },
      getScannedAssets(data) {
        return this.get({url: '/data/policies/assets', data})
          .then((data) => {

            // Return the paginated data in a formatted expected by the table (API v3 format)
            const tablePaginationData = {
              _resources: _.defaults(data.content, []),
              _page: _.defaults(data, {
                number: 0,
                size: 10,
                totalResources: 0,
                totalPages: 0
              })
            };

            return tablePaginationData;
          });
      },
      getAssetSummary(assetID) {
        return this.get({url: `/data/asset/policy/asset/${assetID}`});
      },
      /*
      Asset view - asset summary
      */
      getAssetPolicies(assetID, data) {
        return this.get({url: `/api/3/assets/${assetID}/policies`, data});
      },
      getAssetPoliciesChildren(assetID, policyID, data) {
        return this.get({url: `/api/3/assets/${assetID}/policies/${policyID}/children`, data});
      },
      getAssetPoliciesGroupsChildren(assetID, policyID, groupID, data) {
        return this.get({
          url: `/api/3/assets/${assetID}/policies/${policyID}/groups/${groupID}/children`,
          data
        });
      },
      getAssetPolicyRules(assetID, policyID, data) {
        return this.get({url: `/api/3/assets/${assetID}/policies/${policyID}/rules`, data});
      },
      getAssetPolicyGroupRules(assetID, policyID, groupID, data) {
        return this.get({url: `/api/3/assets/${assetID}/policies/${policyID}/groups/${groupID}/rules`, data});
      },
      getAssetRuleRemediation(policyID, ruleID, data) {
        return this.get({
          url: `/api/3/policies/${policyID}/rules/${ruleID}/remediation`,
          data,
          dataType: 'html'
        });
      },
      getAssetRuleRationale(policyID, ruleID, data) {
        return this.get({
          url: `/api/3/policies/${policyID}/rules/${ruleID}/rationale`,
          data,
          dataType: 'html'
        });
      },
      getAssetRuleProof(assetsID, policyID, ruleID, data) {
        return this.get({
          url: `/api/3/policies/${policyID}/rules/${ruleID}/assets/${assetsID}/proof`,
          data,
          dataType: 'html'
        });
      },
      /*
      Policy rule override
      */
      getOverrideSummary(assetID, ruleUID) {
        return this.get({url: '/data/policy/rule/override/status', data: {assetID, ruleUID}});
      },
      submitPolicyRuleOverride(data) {
        return this.post({url: '/data/policy/rule/override', data});
      },
      /*
      CSV Export
      */
      // set page size to max integer value by default
      buildPaginatedSortedURI(baseEndpoint, sort, sortColumn, filterText, size = 2147483647) {
        var sortParam = '_sort=' + encodeURIComponent(sortColumn + ',' + sort);
        var filterParam = '_filter=' + encodeURIComponent(filterText);
        return baseEndpoint + '?' + sortParam + '&' + filterParam + '&_size=' + size;
      },
      getScannedAssetsTableCSVExportEndpoint(sort, sortColumn, filterText) {
        return this.buildPaginatedSortedURI('/data/policies/assets/csv', sort, sortColumn, filterText);
      },
      getPoliciesTableCSVExportEndpoint(sort, sortColumn, filterText, scannedOnly) {
        var endpoint = this.buildPaginatedSortedURI('/data/policies/csv', sort, sortColumn, filterText);
        if (scannedOnly) {
          endpoint += '&scannedOnly=true';
        }
        return endpoint;
      },
      getPolicyRuleComplianceTableCSVExportEndpoint(policyID) {
        return `/data/policy/${policyID}/rules/csv`;
      },
      getAssetPolicyRuleComplianceTableCSVExportEndpoint(assetID) {
        return `/data/policies/asset/${assetID}/rules/csv`;
      },
      getPolicyAssetsCSVExport(policyID) {
        return `/data/policies/${policyID}/assets/csv`;
      }
    }
  });
};

const PolicyComponent = () => {
  const firstRun = useRef(true);
  const indexPath = '/policies';
  hashHistory.listen(historyListener(indexPath));

  if (firstRun.current) {
    initializeNxPolicy();
    firstRun.current = false;
  }

  return (
    <Router history={hashHistory}>
      <Route path="/">
        <IndexRedirect to={indexPath} />
        {NxPolicy.getRouteHandlers(indexPath)}
      </Route>
    </Router>
  );
};

export default PolicyComponent;
