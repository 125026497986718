/**
 * Get width of element
 * @param {node} elem
 * @param {string} width property (offsetWidth | scrollWidth)
 * @return {number}
 */
const getWidth = (elem, widthType) => Math.max(elem[widthType]);

/*
 * Create a CSS rule that sets the width of the ".nav" element based on the widest
 * child menu item (this is to enable smooth css nav expand and collapse transition).
 * Extended to include sub-menu links.
 */
export default () => {
  const leftNavElems = document.querySelectorAll('.left-nav__text');
  const subMenuElems = document.querySelectorAll('.left-nav__sub-menu-link');
  const elems = [...leftNavElems, ...subMenuElems];

  const biggest = elems.reduce((prev, curr) => {
    const width = curr.classList.contains('left-nav__sub-menu-link') ?
      getWidth(curr, 'scrollWidth') :
      getWidth(curr, 'offsetWidth');

    return width > prev ? width : prev;
  }, 0);

  return biggest + 105;
};