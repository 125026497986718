import _ from 'lodash';
import {Component} from 'react';
import i18n from 'i18next-client';
import dateFormat from 'dateformat';
import Resources from '../locales/Resources';

class NxUpdateCard extends Component {

   state = {
      title: this.props.title,
      version: this.props.version,
      date: this.props.date,
      description: this.props.description,
      isInstalled: this.props.isInstalled,
      updateID: this.props.updateID,
      onUpdateClick: this.props.onUpdateClick,
      onReleaseNotesClick: this.props.onReleaseNotesClick
   }

   constructor(props) {
      super(props);
      i18n.init({
         lng: 'en',
         resStore: Resources
      });
      _.bindAll(this, 'onUpdateClick', 'onReleaseNotesClick');
   }

   onUpdateClick() {
      this.props.onUpdateClick(this.state.updateID, this.state.version);
   }

   onReleaseNotesClick() {
      this.props.onReleaseNotesClick(this.state.version, this.state.date);
   }

   render() {
      var cardHeader, cardAction;
      if (this.state.isInstalled) {
         cardHeader = <div className='nexpose-updates-active-header'></div>;
         cardAction = (
            <div className="nexpose-updates-installed-container">
               <div className="nexpose-updates-installed-icon">
                  <span className="r7-icon r7-icon-standard-check"></span>
               </div>
               <span>{i18n.t('installed')}</span>
            </div>
         );
      } else {
         cardAction = (
            <div>
               <button className="nexpose-updates-btn" onClick={this.onUpdateClick}>
                  {i18n.t('update')}
                  <span className="r7-icon r7-icon-download-arrow"></span>
               </button>
               <div className="nexpose-updates-description">{ this.state.description }</div>
            </div>
         );
      }
      return (
         <div className="nexpose-updates-card">
            {cardHeader}
            <div className="nexpose-updates-title">{ this.state.title }</div>
            <div className="nexpose-updates-secondary-title">{ this.state.version }</div>
            <div className="nexpose-updates-date">{dateFormat(this.state.date, 'mm-dd-yyyy')}</div>
            {cardAction}
            <div className="nexpose-updates-release-footer" onClick={this.onReleaseNotesClick}>{i18n.t('view_release_notes')} ></div>
         </div>
      );
   }
}

export default NxUpdateCard;
