import React from 'react';
import dateFormat from 'dateformat';
import { withTranslation } from 'react-i18next';

class NxUpdateHeader extends React.Component {

  constructor(props) {
      super(props);
      _.bindAll(this, 'onUpdateClick', 'onReleaseNotesClick', 'buildCardInfo', 'checkForUpdates');
   }

   state = {
     currentVersion: '',
     isUserUpdated: true,
     newestVersion   : {},
     updateInProgress: this.props.isUpdateCheckInProgress
   }

   componentWillReceiveProps(nextProps) {
      this.setState({
        currentVersion: nextProps.currentVersion,
        isUserUpdated: nextProps.isUserUpdated,
        newestVersion   : nextProps.newestVersion,
        updateInProgress : nextProps.isUpdateCheckInProgress,
        updateText : nextProps.updateText
      });
   }

   onUpdateClick() {
      this.props.onUpdateClick(this.state.newestVersion.updateID, this.state.newestVersion.version);
   }

   onReleaseNotesClick() {
      this.props.onReleaseNotesClick();
   }

   checkForUpdates() {
      this.props.checkForUpdates();
   }

   buildCardInfo() {
     var cardInfo;
     const {newestVersion = {}} = this.state;
     const {t} = this.props;

     if (newestVersion.version) {
        cardInfo = (
          <div>
            <div className="header-title">{t('version')}</div>
            <div className="header-version">{newestVersion.version}</div>
            <div className="header-date">{dateFormat(newestVersion.date, 'mm-dd-yyyy')}</div>
          </div>
        );
      } else {
        cardInfo = (
          <div>
            <div className="header-title-no-updates">{t('no_updates_available')}</div>
          </div>
        );
      }

      return cardInfo;
   }

  render() {
    var cardInfo = this.buildCardInfo();
    var cardAction;
    var checkForUpdates;
    const {newestVersion = {}} = this.state;
    const {t} = this.props;

    if (this.state.updateInProgress) {
      checkForUpdates = (<button className="btn btn-primary check-for-updates" disabled><i className="fa fa-spinner fa-spin"></i> {t('check_for_updates')}</button>);
    } else {
      checkForUpdates = (<button className="btn btn-primary check-for-updates" onClick={this.checkForUpdates}>{t('check_for_updates')}</button>);
    }
    if (this.state.isUserUpdated && newestVersion.version) {
         cardAction = (
            <div className="nexpose-updates-installed-container">
               <div className="nexpose-updates-installed-icon">
                  <span className="r7-icon r7-icon-standard-check"></span>
               </div>
               <span>{t('installed')}</span>
               <div className="header-description">
                  <div className="nexpose-header-installed-release-notes" onClick={this.onReleaseNotesClick}>{t('view_release_notes')} ></div>
               </div>
            </div>
         );
      } else if (!newestVersion.version) {
        cardAction = (
            <div/>
         );
      } else {
         cardAction = (
            <div>
               <button className="nexpose-updates-btn" onClick={this.onUpdateClick}>
                  {t('update_to_latest')} <span className="r7-icon r7-icon-download-arrow"></span>
               </button>
               <div className="header-description">{t('install_text', {versionNum: newestVersion.version})} | <span className="nexpose-header-release-notes" onClick={this.onReleaseNotesClick}>{t('view_release_notes')} ></span></div>
            </div>
         );
      }
    return (
      <div className="nexpose-update-header">
         { !this.state.isUserUpdated &&
            <div className="update-prompt-container">
              <div className="update-prompt">
                 <i className="r7-icon r7-icon-download-arrow"></i>
                 {t('installed_text', {versionNum: this.state.currentVersion})} | <a onClick={this.onUpdateClick}>{t('upgrade_to_latest', {versionNum: newestVersion.version})}</a>
              </div>
           </div>
         }
         <div className="main-header">
            <div className="nexpose-update-title-container">
               {  newestVersion.version &&
                  <div>
                    <div className="main-title">{t('current_release')}</div>
                    <a target="_blank" rel="noopener noreferrer" href="https://help.rapid7.com/nexpose/en-us/release-notes/archive/" className="btn btn-primary main-archive">{t('view_release_archive')}</a>
                  </div>
               }
               {checkForUpdates}
               <p className="update-response-text">{this.state.updateText}</p>
            </div>
            <div className="headers">
               {cardInfo}
               {cardAction}
            </div>
         </div>
      </div>
    );
  }
}

export default withTranslation(['update', 'translation'])(NxUpdateHeader);
