import ReactDOM from 'react-dom';
import {get} from 'lodash';
import { initializeI18n } from './i18n';
import App from './app';

initializeI18n(get(document, 'nxp.user.language'));

var renderApp = () => ReactDOM.render(
	<App {...document.nxp} />,
	document.getElementById('nx-react-app')
);

//Data is being shared between multiple frameworks (refer to dataLoader.js)
//If the data is already loaded, render app, else wait for the data to be loaded
if (document.nxp && document.nxp.isLoaded) {
	renderApp();
} else {
	document.addEventListener('init', renderApp);
}
