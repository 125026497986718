import isFunction from 'lodash/isFunction';

//eslint-disable-line
const analyticsListener = ({pathname}, indexPath) => {
  if (pathname !== indexPath) {
    var w = window;
    
    if (w.onPendoLoaded) {
      w.onPendoLoaded(function() {
        w.pendo.pageLoad();
      });
    }
  }
};

export default (indexPath, listenerFns = []) => {
  listenerFns.push(analyticsListener);

  return (location) => listenerFns.forEach((fn) => isFunction(fn) && fn(location, indexPath));
};