/**
 * The translations in this file must be maintained in alphabetic order.git status
 *
 */
const Resources = {
  'en': {
    'translation': {
		'installed'         : 'Installed',
		'install_text'      : 'Includes v__versionNum__ and older updates',
		'update'            : 'Update',
		'view_release_notes': 'Release Notes',
		'version'           : 'Version'
    }
  }
};

export default Resources;
