import $ from 'jquery';

export default class NotificationsService {

  disableNotification = (sessionID, notificationID) => (
    $.ajax({
      url: '/data/product/notifications/' + encodeURIComponent(notificationID) + '/disable',
      type: 'PUT',
      headers: {'nexposeCCSessionID': sessionID}
    })
  );
}