import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
  * Stop gap measure to use same data between two different frameworks. 
  * Needs to be moved to react once everything is moved from marionnetted.
  * 
  * Packaged wherever the topNav resides -- currently nexpose-react.js
  */
 (function()
 {
    var fireInitEvent = function() {
      if (!document.nxp) {
        document.nxp = {};
      }
      
      document.nxp.isLoaded = true;

      var event = document.createEvent('Event');
      event.initEvent('init', true, false); 
      document.dispatchEvent(event);
    }
 
   if (document.nxp && document.nxp.sessionID) {
     $.ajax({
         url: '/data/application-details',
         type: 'GET',
         dataType: 'json',
         data: null,
         contentType: 'application/json; charset=utf-8',
         headers: {"nexposeCCSessionID": document.nxp.sessionID}
       }).done(function(result) {
         document.nxp.appOptions = result;
 
         fireInitEvent();
       });

   } else {
    fireInitEvent();
   }
 })();