import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const DEFAULT_LANGUAGE = 'en-US';
const DEFAULT_NAMESPACE = 'dictionary';
const namespaces = [
	'dictionary',
	'insightvmNavMenus',
	'ipimsSessionConflict',
	'notification',
	'policies',
	'update',
	'banner'
];

const loadResourceByNamespace = (locale, namespace) => {
	try {
		return require(`../../../../locales/${locale}/${namespace}.json`);
	} catch {
		return {};
	}
};

export const upcaseCountry = (locale) => {
	const parts = locale.split('-');
	
  return parts.length > 1 ? `${parts[0]}-${parts[1].toUpperCase()}` : null;
};

export const addLocaleResources = (locale) => (
	namespaces.map((namespace) => {
		i18n.addResourceBundle(
			locale,
			namespace,
			loadResourceByNamespace(locale, namespace),
			true, 
			true
		);

		if (locale !== DEFAULT_LANGUAGE) {
			i18n.addResourceBundle(
				DEFAULT_LANGUAGE,
				namespace,
				loadResourceByNamespace(DEFAULT_LANGUAGE, namespace),
				true, 
				true
			);
		}
	})
);

export const initializeI18n = (locale = DEFAULT_LANGUAGE) => {
	const formattedLocale = upcaseCountry(locale);

	if (i18n.isInitialized) {
		i18n.changeLanguage(formattedLocale); 
		i18n.setDefaultNamespace(DEFAULT_NAMESPACE);
	} else {
		i18n
		.use(initReactI18next)
		.init({
			defaultNS: DEFAULT_NAMESPACE,
			fallbackLng: DEFAULT_LANGUAGE,
			fallbackNS: DEFAULT_NAMESPACE,
			resources: {},
			interpolation: {
				prefix: '__',
				suffix: '__'
			},
			lng: formattedLocale
		});
	}

	return addLocaleResources(formattedLocale);
};