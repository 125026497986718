import {useState} from 'react';
import { withTranslation } from 'react-i18next';
import {Button} from '@rapid7/rapid7-ui';
import styled from 'styled-components';
import { getColor } from '@rapid7/rapid7-ui/es/utils';

const Container = styled.div`
  background-color: ${getColor('gray.75')};
`;

const InnerContainer = styled.div`
  width: 350px;
  margin: 0 auto;
  text-align: center;
  color: ${getColor('darkText.body')};
`;

const Content = styled.p`
  margin: 20px 0 40px;
`;

const Header = styled.h1`
  width: 350px;
  font-size: 20px;
  margin: 15px auto;
  color: inherit;
  line-height: 1.4;
`;

const IpimsSessionConflict = ({t}) => {
  const [redirectButtonIsVisible, showRedirectButton] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  return (
    <Container>
      <InnerContainer>
        <img src="/images/general-error.png" className="errorImage"/>
        <Header>{t('already_active_session')}</Header>
        <Content>
          {t('only_have_one_session')}
        </Content>
        {redirectButtonIsVisible && <a href={`/ea/redirect.jsp?page=${urlParams.get('page')}`}><Button context="primary">{t('continue')}</Button></a>}
        {!redirectButtonIsVisible && (
          <div>
            <a href="https://insight.rapid7.com/saml/logout?local=true" target="_blank" rel="noopener noreferrer">
              <Button context="primary" onClick={showRedirectButton}>
                {t('log_out', {ipimsLogin: urlParams.get('ipimsLogin')})}
              </Button>
            </a>
            <a href="/home.jsp">
              <Button context="ghost">{t('go_home')}</Button>
            </a>
          </div>
        )}
      </InnerContainer>
    </Container>
  );
};

export default withTranslation(['ipimsSessionConflict'])(IpimsSessionConflict);
