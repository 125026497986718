import {Alert} from 'react-bootstrap';
import {getColor} from '@rapid7/rapid7-ui/lib/utils';

function NotificationBanner({color, notificationText, isDismissable, onDismiss}) {
    return (
        <Alert
            {...{
                style: {
                    marginLeft: '60px',
                    marginTop: '60px',
                    borderRadius: '0px',
                    color: '#FFFFFF',
                    backgroundColor: color,
                    borderColor: color,
                    fontSize: '14px',
                    padding: '12px 35px 8px 20px',
                    position: 'fixed',
                    width: 'calc(100% - 60px)',
                    minHeight: '47px',
                    'z-index': '10'
                },
                ...(onDismiss && isDismissable && {onDismiss: onDismiss})
            }}
        >
            <span dangerouslySetInnerHTML={{__html: notificationText}}/>
        </Alert>
    );
}

export const getGracePeriodBannerColor = (ssoGracePeriodDaysLeft) => {
    let color = getColor('neutralRfcSeverityLevel.warning');
    if (ssoGracePeriodDaysLeft <= 3) {
        color = getColor('neutralRfcSeverityLevel.alert');
    } else if (ssoGracePeriodDaysLeft <= 10) {
        color = getColor('neutralRfcSeverityLevel.critical');
    }

    return color;
};

export default NotificationBanner;
