/**
 * store data in local storage with simple versioning
 *
 * @param key the key for which we want to retrieve a locally stored value
 * @param defaultValue the default value; used when the key does not exist, or if the version of the stored data is lower than @param version
 * @param version a number which indicates the version of the stored data. if the data stored is of a lower version than the version desired (@param version), then the default value will be used
 *
 * @returns data stored in local storage (or default value)
 */
export function getLocalData(key, defaultValue, version = 0) {
    const localStorageString = localStorage.getItem(key);
  
    if (localStorageString) {
      try {
        const dataHolder = JSON.parse(localStorageString);
  
        return dataHolder.version === version ? dataHolder.data : defaultValue;
      } catch (e) {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  }
  
  /**
   * unwraps/parses data that was locally stored with getData
   *
   * @param key the key used to store data in local storage
   * @param data the data to set for the given key
   * @param version the version to set for the given key
   */
  export function setLocalData(key, data, version = 0) {
    localStorage.setItem(
      key,
      JSON.stringify({
        data,
        version
      })
    );
  }
  
  /**
   * removes the stored data
   *
   * @param key the key used to store data in local storage
   */
  export function removeLocalData(key) {
    localStorage.removeItem(key);
  }
  