export const getPolicyFeatures = () => {
  /**
   * This module manages which features are enabled that can be disabled by the consuming application.
   */
  let PolicyFeatures = {
    /**
     * Enables management of custom policies for the user. This includes copy of built-in/custom policies, edit, and
     * deletion of custom policies.
     */
    managePoliciesEnabled: false,

    /**
     * Allows the user to submit requests to override policy results.
     */
    submitOverridesEnabled: false,

    /**
     * Allows the user to approve or reject policy override results.
     */
    reviewOverridesEnabled: false,

    /**
     * Allows the user to delete either submitted or approved policy override results.
     */
    deleteOverridesEnabled: false,

    /**
     * Enables policy upload functionality for the user.
     */
    uploadPolicyEnabled: false,

    /**
     * Enables management of custom policies for the user through the new cloud InsightVM policy editor. This includes copying of built-in/custom policies and
     * enhanced editing of custom policies.
     */
    cloudPolicyEditorEnabled: false
  };

  /*
  * FIXME: Relying on document.nxp is temporary until we refactor the javascript code for managing feature sets, oem
  * config, and user privileges from the marionette sub-project so that it's available for all framework stacks.
  */
  let { priv, featureSet, console: nxConsole } = document.nxp;
  try {
    PolicyFeatures.managePoliciesEnabled = (priv.fullControl || priv.managePolicies) && featureSet.getSupportsLicensedOption('EDITOR');
  } catch (e) {
    console.error('Unable to initialize feature: managePolicies. ', e); // eslint-disable-line no-console
  }

  try {
    PolicyFeatures.submitOverridesEnabled = priv.fullControl || priv.submitVulnExceptions;
  } catch (e) {
    console.error('Unable to initialize feature: submitOverrides. ', e); // eslint-disable-line no-console
  }

  try {
    PolicyFeatures.reviewOverridesEnabled = priv.fullControl || priv.approveVulnExceptions;
  } catch (e) {
    console.error('Unable to initialize feature: reviewOverrides. ', e); // eslint-disable-line no-console
  }

  try {
    PolicyFeatures.deleteOverridesEnabled = priv.fullControl || priv.deleteVulnExceptions;
  } catch (e) {
    console.error('Unable to initialize feature: deleteOverrides. ', e); // eslint-disable-line no-console
  }

  try {
    PolicyFeatures.uploadPolicyEnabled = (priv.fullControl || priv.managePolicies) && featureSet.getSupportsLicensedOption('APECUSTOMPOL');
  } catch (e) {
    console.error('Unable to initialize feature: uploadPolicy. ', e); // eslint-disable-line no-console
  }

  try {
    PolicyFeatures.cloudPolicyEditorEnabled = nxConsole.optedIn && (priv.fullControl || priv.managePolicies) && featureSet.getSupportsLicensedOption('EDITOR');
  } catch (e) {
    console.error('Unable to initialize feature: cloudPolicyEditor. ', e); // eslint-disable-line no-console
  }

  return PolicyFeatures;
};
